const initialState = {
    schemaData: {
        data: {list: []},
        status: '',
        isPending: false,
    },
};

const SchemaReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'GET_SCHEMA_DATA_PENDING': {
            const schemaData = {
                ...state.schemaData,
                data: payload,
                status: '',
                isPending: true,
            }
            return {...state, schemaData};
        }

        case 'GET_SCHEMA_DATA_FULFILLED': {
            const schemaData = {
                ...state.schemaData,
                data: payload,
                status: '',
                isPending: false,
            }
            return {...state, schemaData};
        }

        default:
            return state;
    }
};

export {SchemaReducer};