import { combineReducers } from 'redux'
import { AuthReducer } from './Auth'
import { ProjectsReducer, ProjectReducer, CollapsedReducer, TaskReducer } from './Project'
import { ImageReducer } from './Image'
import { ClassReducer } from './Class'
import { ResultsReducer } from './Inference'
import { SchemaReducer } from "./Schema"
import {CurrentImageReducer, imagesReducer, imageIndexesReducer, pageReducer, labelDataReducer, unfinishedFigureReducer,
  selectedKeysReducer, expandedKeysReducer, selectedFigureIdReducer, figuresReducer, sizeReducer, clockwiseAndAngleReducer,
  selectLabelIdReducer, inferenceFunReducer, treeDataReducer, labeledReducer, initLabelDataReducer, submitLoadingReducer,
  leftCollapsedReducer, rightCollapsedReducer} from './Label'

const rootReducer = combineReducers({
  auth: AuthReducer,
  projects: ProjectsReducer,
  image: ImageReducer,
  class: ClassReducer,
  project: ProjectReducer,
  schema: SchemaReducer,
  results: ResultsReducer,
  collapsed: CollapsedReducer,
  currentImage: CurrentImageReducer,
  images: imagesReducer,
  imageIndexes: imageIndexesReducer,
  page: pageReducer,
  selectedKeys: selectedKeysReducer,
  expandedKeys: expandedKeysReducer,
  selectedFigureId: selectedFigureIdReducer,
  figures: figuresReducer,
  size: sizeReducer,
  clockwiseAndAngle: clockwiseAndAngleReducer,
  labelData: labelDataReducer,
  unfinishedFigure: unfinishedFigureReducer,
  selectLabelId: selectLabelIdReducer,
  inferenceFun: inferenceFunReducer,
  treeData: treeDataReducer,
  labeled: labeledReducer,
  initLabelData: initLabelDataReducer,
  submitLoading: submitLoadingReducer,
  leftCollapsed: leftCollapsedReducer,
  rightCollapsed: rightCollapsedReducer,
  task: TaskReducer
})

export { rootReducer }
