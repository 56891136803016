import Cookies from 'js-cookie';

const initialState = [
    {
        data: {},
        status: '',
        signed: false,
    },
];

const AuthReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'LOGIN_REJECT':
            return [
                {
                    data: payload.data,
                    signed: payload.signed,
                    status: payload.status,
                },
            ];
        case 'LOGIN_SUCCESS':
            Cookies.set('token', payload.data.token, {expires: 7});
            Cookies.set('username', payload.data.username);
            Cookies.set('user_id', payload.data.user_id);
            Cookies.set('organization_name', payload.data.organization_name);
            Cookies.set('permission', payload.data.permission_level);
            return [
                {
                    data: payload.data,
                    signed: payload.signed,
                    status: payload.status,
                },
            ];
        case 'LOGOUT_ACTION':
            Cookies.remove('token');
            Cookies.remove('state');
            Cookies.remove('csrftoken');
            Cookies.remove('username');
            Cookies.remove('organization_name');
            Cookies.remove('sessionid');
            Cookies.remove('projectInfo');
            Cookies.remove('list');
            Cookies.remove('projectName');

            return [
                {
                    data: payload.data,
                    signed: payload.signed,
                    status: payload.status,
                },
            ];
        default:
            return state;
    }
};

export {AuthReducer};
