const ResultsReducer = (state = [], action) => {
    const {type, payload} = action
    switch (type) {
        case 'RESULTS_SET':
            return payload
        default:
            return state
    }
}

export {ResultsReducer}
