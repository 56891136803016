import {applyMiddleware, createStore} from 'redux'
import {rootReducer} from './reducers/index'
import reduxPromise from "redux-promise-middleware"

const middleware = [reduxPromise]

const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);

export default store