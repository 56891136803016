import React from 'react';

const AdminApp = React.lazy(() => import('../views/admin/AdminApp'));
const Project = React.lazy(() => import('../views/project/Project'));
const Login = React.lazy(() => import('../views/home/Login'));

const privateRoutes = [
    {
        path: '/',
        component: [Login, AdminApp],
        exact: true,
    },
    {
        path: '/project',
        component: Project,
        exact: false,
        backUrl: '/',
    },
];

export default privateRoutes;
