import {getProjectId} from '../../utils/util'

const initialState = [
    {
        data: {},
        status: '',
        signed: true,
    },
];

let project = {}
if (getProjectId()) project = {pk: getProjectId()}


const ProjectsReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'PROJECTS_GET_FULFILLED':
            return [
                {
                    ...state,
                    data: payload,
                    signed: payload.signed,
                    status: payload.status,
                },
            ];
        default:
            return state;
    }
};

const ProjectReducer = (state = project, action) => {
    const {type, payload} = action
    switch (type) {
        case 'PROJECT_GET_FULFILLED':
        case 'PROJECT_GET':
            return payload
        default:
            return state
    }
}

const CollapsedReducer = (state = false, action) => {
    const {type, payload} = action
    switch (type) {
        case 'SWITCH_COLLAPSE':
            return payload
        default:
            return state
    }
}

const TaskReducer = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_TASK':
            return payload
        default:
            return state
    }
}

export {ProjectsReducer, ProjectReducer, CollapsedReducer, TaskReducer};
