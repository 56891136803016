import API from '../../services/Project'

const getProjects = () => {
    return {
        type: 'PROJECTS_GET',
        payload: API.getProjects(),
    };
};

const getProject = (payload) => {
    return {
        type: 'PROJECT_GET',
        payload,
    }
}

const createProject = () => {
    return {
        type: 'PROJECT_CREATE',
        payload: API.createProject(),
    };
};

const updateProject = (projectID, projectName) => {
    return {
        type: 'PROJECT_UPDATE',
        payload: API.updateProject(projectID, projectName),
    };
}

const switchCollapse = (collapsed) => {
    return {
        type: 'SWITCH_COLLAPSE',
        payload: collapsed
    }
}

export const setTask = (payload) => {
    return {
        type: 'SET_TASK',
        payload,
    }
}

const projectActions = {
    getProjects,
    createProject,
    updateProject,
    getProject,
    switchCollapse,
    setTask
};

export default projectActions;
