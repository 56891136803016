import React from 'react';

const SignupPage = React.lazy(() => import('../views/home/SignupPage'));
const ResetPassword = React.lazy(() => import('../views/home/RestPassword'));

const publicRoutes = [
    {
        path: '/signup',
        component: SignupPage,
        exact: false,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        exact: false,
    },
];

export default publicRoutes;
