const initialState = {
    labeledImages: {
        images: {api_request: {}},
        error: null,
        isPending: true,
    },
    allImages: {
        data: {},
        error: null,
        isPending: true,
    },
};

const ImageReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'GET_LABELED_IMAGE_PENDING': {
            const labeledImages = {
                ...state.labeledImages,
                images: payload,
                error: null,
                isPending: true,
            }
            return {...state, labeledImages};
        }

        case 'GET_LABELED_IMAGE_FULFILLED': {
            const labeledImages = {
                ...state.labeledImages,
                images: payload,
                error: null,
                isPending: false,
            }
            return {...state, labeledImages};
        }

        case 'GET_ALL_IMAGE_PENDING':
            return [
                {
                    ...state.allImages,
                    data: payload,
                    signed: payload.signed,
                    status: payload.status,
                },
            ];
        case 'GET_ALL_IMAGE_FULFILLED':
            return [
                {
                    ...state.allImages,
                    data: payload,
                    signed: payload.signed,
                    status: payload.status,
                },
            ];
        default:
            return state;
    }
};

export {ImageReducer};
