import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import Cookies from 'js-cookie';

function AuthRoute(props) {
    const {backUrl, component, ...otherProps} = props;

    if (props.path === '/') {
        if (Cookies.get('token')) {
            return <Route component={component[1]} {...otherProps}/>
        } else {
            return <Route component={component[0]} {...otherProps}/>
        }
    }
    if (Cookies.get('token')) {
        return <Route component={component} {...otherProps} />;
    } else {
        return <Redirect to={backUrl}/>;
    }
}

export default AuthRoute;
