import axios from "axios";

axios.defaults.withCredentials = true;

const getTaskList = async (projectID) => {
    return axios.get(`/api/create_task_list/?project_id=${projectID}`);
}

const getTasks = async () => {
    return axios.get(`/api/get_tasks/`);
}

const createTask = async (user, type, description, project) => {
    return axios.post(`/api/create_task_list/`, {user, type, description, project});
}

const deleteTask = async (taskID) => {
    return axios.delete(`/api/edit_task_list/${taskID}/`);
}

const getTaskItems = async (taskID) => {
    return axios.get(`/api/task/${taskID}/`);
}

const editTaskItems = async (taskID, images) => {
    return axios.post(`/api/task/${taskID}/`, {images});
}

const deleteTaskItems = async (taskID, imageID) => {
    return axios.delete(`/api/edit_task_item/${taskID}/${imageID}`);
}


const taskServices = { getTaskList, getTasks, createTask, deleteTask, getTaskItems, editTaskItems, deleteTaskItems };
export default taskServices;